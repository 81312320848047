// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-articles-jsx": () => import("./../../../src/pages/articles.jsx" /* webpackChunkName: "component---src-pages-articles-jsx" */),
  "component---src-pages-chipions-jsx": () => import("./../../../src/pages/chipions.jsx" /* webpackChunkName: "component---src-pages-chipions-jsx" */),
  "component---src-pages-courses-jsx": () => import("./../../../src/pages/courses.jsx" /* webpackChunkName: "component---src-pages-courses-jsx" */),
  "component---src-pages-gallery-jsx": () => import("./../../../src/pages/gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-magazines-jsx": () => import("./../../../src/pages/magazines.jsx" /* webpackChunkName: "component---src-pages-magazines-jsx" */),
  "component---src-pages-two-hours-per-weeks-jsx": () => import("./../../../src/pages/twoHoursPerWeeks.jsx" /* webpackChunkName: "component---src-pages-two-hours-per-weeks-jsx" */),
  "component---src-templates-article-jsx": () => import("./../../../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-bio-jsx": () => import("./../../../src/templates/bio.jsx" /* webpackChunkName: "component---src-templates-bio-jsx" */),
  "component---src-templates-course-jsx": () => import("./../../../src/templates/course.jsx" /* webpackChunkName: "component---src-templates-course-jsx" */)
}

